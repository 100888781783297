import SmallContainer from "../containers/SmallContainer";
import Register from "./Register";
import Login from "./Login";

const UserMenu = ({}) => {
  return (
    <div className="relative flex flex-row w-3/5 md:w-1/4">
      <SmallContainer>
        <Register />
      </SmallContainer>
      <SmallContainer green>
        <Login />
      </SmallContainer>
    </div>
  );
};

export default UserMenu;
