import React from "react";
import Logo from "./Logo";
import Search from "./Search";
import UserMenu from "./UserMenu";
import Categories from "./Categories";
import AdvancedSearch from "./AdvancedSearch";
import { useLocation, useParams } from "react-router-dom";

interface INavProps {
  children: React.ReactNode;
}

const Navbar: React.FC<INavProps> = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isNotAccountNav = !location.pathname.startsWith("/account");

  return (
    <>
      <div className="fixed  w-full bg-customModal z-10 shadow-s,">
        {isNotAccountNav ? (
          <>
            <div
              className="
                        flex 
                        flex-row
                        items-center
                        "
            >
              <div
                className="
                            xl:ml-20
                            md:ml-10
                            sm:ml-2
                            ml-4
                            flex
                            w-full
                            flex-row
                            items-center
                            justify-between
                            gap-3
                            md:gap-0
                            "
              >
                <span
                  className={`${
                    isHomePage
                      ? "hidden md:flex md:ml-10 xl:ml-20"
                      : "ml-10 xl:ml-20"
                  }`}
                >
                  <Logo />
                </span>
                <p className="hidden max-w-[210px] justify-center md:block">
                  Artistas e produtores de eventos em um só lugar.
                </p>
                <Search />
                <span
                  className={`
                    ${isHomePage ? "" : "hidden md:flex"}
                    `}
                >
                  <AdvancedSearch />
                </span>

                <UserMenu />
              </div>
            </div>
            <Categories />
          </>
        ) : (
          <>
            <div
              className="
                            flex
                            flex-row
                            py-5
                            bg-customCards
                        "
            >
              <span className="md:flex ml-20">
                <Logo />
              </span>
            </div>
          </>
        )}
      </div>

      <div
        className="
                pt-44
                "
      >
        {children}
      </div>
    </>
  );
};

export default Navbar;
