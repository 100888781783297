import React from "react";

import { FaCamera } from "react-icons/fa";

interface MiniAvatarProps{
    src: string | null | undefined;
    big?: boolean  
}

const MiniAvatar: React.FC<MiniAvatarProps> = ({
    src,
    big,
}) =>{
    return(
      <img
        alt="MiniAvatar"
        className={
          `rounded-full
          ${big && 'border-[8px] border-lilasHover'}
          `
        }
        height={big ? 214 : 40}
        width={big ? 214 : 40}
        src={src || "/images/avatar.png"}
      />
    )
}

export default MiniAvatar