import React from 'react'
import { IconType } from "react-icons";


interface ISelectButton{
    label: string;
    onClick : () => void;
    selected?: boolean; 
    icon: IconType;

}

const SelectButton: React.FC<ISelectButton> = ({
    icon: Icon,
    label,
    onClick,
    selected
}) => {
  return (
    <button
        className={`flex flex-row items-center gap-1 cursor-pointer ${selected ? 'text-lilas cursor-not-allowed': 'hover:text-lilasHover'} `}
        onClick={onClick}
    >
        <Icon />
        <label className='cursor-pointer'>
            {label}
        </label>
    </button>
  )
}

export default SelectButton