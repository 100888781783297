import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import InputItemCard from './InputItemCard';
import { Control, FieldValues, useFieldArray} from 'react-hook-form';
import ItemCard from '../../models/ItemCard';



type InputData = {
    label1: string;
    label2: string;
    label3: string;
  };

type DynamicItemCardProps = {
    fieldName: string;  
    inputData: InputData;
    control: Control<FieldValues,any>;
    imagePathName: string;
    initialValues: ItemCard[] | undefined

}

const DynamicItemCard: React.FC<DynamicItemCardProps> = ({
    fieldName,
    inputData,
    imagePathName,
    control,
    initialValues
}) => {
  const [inputs, setInputs] = useState([{ name: '', subName: '', photo: '', active: true },{ name: '', subName: '', photo: '', active: false }]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName
  });

  const activate = (index: number) =>{
    const activeInput = [...inputs];
    activeInput[index] = {
      ...activeInput[index],
      ["active"] : true
    };
    setInputs([...activeInput, { name: '', subName: '', photo: '', active: false }]);

  }


  useEffect(() => {
    if (initialValues) {
      const updatedInitialValues = initialValues
        .map(value => {
          if (value.name == null) return null; // Retorna null para valores inválidos
          return {
            ...value,
            active: true
          };
        })
        .filter((value): value is ItemCard & { active: boolean } => value !== null); // Filtra valores null e garante o tipo
  
      setInputs([...updatedInitialValues,{ name: '', subName: '', photo: '', active: true },{ name: '', subName: '', photo: '', active: false }]);
    }
  }, [initialValues]);

  const handleInputChange = (index: number, field: string, value: string) => {
    setInputs(prevInputs=>{
      const newInputs = [...prevInputs];
      newInputs[index] = {
        ...newInputs[index],
        [field]: value
      };

      if (index === inputs.length - 2 && newInputs[index].name.trim() !== '' && newInputs[index].subName.trim() !== '') {
        newInputs[index+1] = {
          ...newInputs[index+1],
          ["active"] : true
        };
        return [...newInputs, { name: '', subName: '', photo: '', active: false }];
      }
      return newInputs; 
    })

  };


  const cleanInput = (index: number) => {
    const cleanInputs = [...inputs];
    cleanInputs.splice(index, 1);
    setInputs(cleanInputs);
    remove(index)
  }


 

  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-7">
        <AnimatePresence>
          {inputs.map((input, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              transition={{ duration: 0.5 }}
              className="relative flex flex-row"
            >
              <InputItemCard
                control={control}
                imagePathName={imagePathName}
                index={index}
                id1={`${fieldName}.${index}.name`}
                id2={`${fieldName}.${index}.subName`}
                id3={`${fieldName}.${index}.photo`}
                label1={inputData.label1}
                label2={inputData.label2}
                label3={inputData.label3}
                value1={input.name}
                value2={input.subName}
                value3={input.photo !== ''? [input.photo] : [] }
                onChange={(field:  any, value: string) => handleInputChange(index, field, value)}
                active = {input.active}
                onRemove = {() => cleanInput(index)}
                onActivate = {()=> activate(index)}
              />
              
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default DynamicItemCard;