import { AiOutlineThunderbolt } from "react-icons/ai";

const MUSIC_IMAGES = "/musicStyles/";
const EVENT_IMAGES = "/eventType/";

export const musicStyles = [
  {
    label: "Eletrônica",
    png: MUSIC_IMAGES + "eletronica.png",
    value: "Eletrônica",
  },
  {
    label: "Funk Brasileiro",
    png: MUSIC_IMAGES + "funkbrasileiro.png",
    value: "Funk Brasileiro",
  },
  {
    label: "Soul",
    png: MUSIC_IMAGES + "Soul.png",
    value: "Soul",
  },
  {
    label: "Open Format",
    png: MUSIC_IMAGES + "OpenFormat.png",
    value: "Open Format",
  },
  {
    label: "Sertanejo",
    png: MUSIC_IMAGES + "Sertanejo.png",
    value: "Sertanejo",
  },
  {
    label: "Samba",
    png: MUSIC_IMAGES + "Samba.png",
    value: "Samba",
  },
  {
    label: "Pagode",
    png: MUSIC_IMAGES + "Pagode.png",
    value: "Pagode",
  },
  {
    label: "Forró",
    png: MUSIC_IMAGES + "forro.png",
    value: "Forró",
  },
  {
    label: "Axé",
    png: MUSIC_IMAGES + "axe.png",
    value: "Axé",
  },
  {
    label: "Brega",
    png: MUSIC_IMAGES + "eletronica.png",
    value: "Brega",
  },
  {
    label: "Clássica",
    png: MUSIC_IMAGES + "classica.png",
    value: "Clássica",
  },
  {
    label: "Rock",
    png: MUSIC_IMAGES + "Rock.png",
    value: "Rock",
  },
  {
    label: "Pop",
    png: MUSIC_IMAGES + "Pop.png",
    value: "Pop",
  },
  {
    label: "MPB",
    png: MUSIC_IMAGES + "MPB.png",
    value: "MPB",
  },
  {
    label: "Góspel",
    png: MUSIC_IMAGES + "gospel.png",
    value: "Góspel",
  },
  {
    label: "Tradicional",
    png: MUSIC_IMAGES + "MPB.png",
    value: "Tradicional",
  },
  {
    label: "Jazz",
    png: MUSIC_IMAGES + "Jazz.png",
    value: "Jazz",
  },
  {
    label: "Blues",
    png: MUSIC_IMAGES + "Blues.png",
    value: "Blues",
  },
  {
    label: "Reggae",
    png: MUSIC_IMAGES + "Reggae.png",
    value: "Reggae",
  },
  {
    label: "Latina",
    png: MUSIC_IMAGES + "Latina.png",
    value: "Latina",
  },
];

export const eventTypes = [
  {
    label: "Banda",
    png: EVENT_IMAGES + "banda.png",
    value: "music",
  },
  {
    label: "Coral",
    png: EVENT_IMAGES + "coral.png",
    value: "music",
  },
  {
    label: "Trio",
    png: EVENT_IMAGES + "trio.png",
    value: "music",
  },
  {
    label: "Orquestra",
    png: EVENT_IMAGES + "orquestra.png",
    value: "music",
  },
  {
    label: "Dupla",
    png: EVENT_IMAGES + "dupla.png",
    value: "music",
  },
  {
    label: "DJ + Instrumentista",
    png: EVENT_IMAGES + "djinstrumentista.png",
    value: "music",
  },
  {
    label: "DJ + Bailarino",
    png: EVENT_IMAGES + "djbailarino.png",
    value: "music",
  },
  {
    label: "DJ",
    png: EVENT_IMAGES + "dj.png",
    value: "music",
  },
  {
    label: "Instrumentista",
    png: EVENT_IMAGES + "instrumentista.png",
    value: "music",
  },
  {
    label: "Cantor Solo",
    png: EVENT_IMAGES + "cantorsolo.png",
    value: "music",
  },
];
