import React,{useCallback,useState,useEffect} from "react";
import {IoMdClose} from 'react-icons/io'
import Button from "../Button";


interface ModalProps{
    isOpen?:boolean;
    onClose: () => void;
    onSubmit: () =>void;
    title?: string;
    body?: React.ReactElement;
    footer?: React.ReactElement;
    paragraphFooter?: React.ReactElement;
    centralizeParagraph?: boolean;
    actionLabel: string;
    disabled?: boolean;
    secondaryAction?: () => void;
    secondaryActionLabel? : string;
    register?: boolean
}


const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    title,
    body,
    footer,
    actionLabel,
    disabled,
    secondaryAction,
    secondaryActionLabel,
    register = false,
    centralizeParagraph = false,
    paragraphFooter,
}) =>{
    const [showModal,setShowModal] = useState(isOpen);
    
    useEffect(()=>{
        setShowModal(isOpen)
    },[isOpen])


    


    const handleClose = useCallback(()=>{
        if(disabled) return
        
        setShowModal(false)
        setTimeout(()=>{
            onClose();
        },300);

    },[disabled,onClose]);

    const handleSubmit = useCallback(()=>{
        if(disabled) return

        onSubmit();
    },[disabled,onSubmit]);


    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
          if (e.key === "Enter") {
            (e.target as HTMLInputElement).blur();
            handleSubmit();
          }
        };
    
        if (showModal) {
          window.addEventListener("keydown", handleKeyDown);
        } else {
          window.removeEventListener("keydown", handleKeyDown);
        }
    
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }, [showModal, handleSubmit]);

    const handleSecondaryAction = useCallback(()=>{
        if(disabled || !secondaryAction) return

        secondaryAction()
    },[disabled,secondaryAction]);

    if(!isOpen){
        return null;
    }

    
    
    const handleBackgroundClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    }

    

    


    return(
        <>
            <div
                className="
                    justify-center
                    items-center
                    flex
                    overflow-x-hidden
                    overflow-y-auto
                    fixed
                    inset-0
                    z-50
                    outline-none
                    focus:outline-none
                    
                "
                onClick={handleBackgroundClick}
            >
                <div
                    className="
                        relative
                        w-full
                        md:w-4/6
                        lg:w-4/6
                        xl:w-2/5
                        my-6
                        mx-auto
                        h-auto
                        lg:h-auto
                        md:h-auto
                    "
                >
                    {/*conteudo*/}
                    <div
                        className={`
                            tranlate 
                            duration-300
                            h-full
                            ${showModal ? 'tranlate-y-0': 'translate-y-full'}    
                            ${showModal ? 'opacity-100': 'opacity-0'}    
                        `}
                    >
                        <div
                            className="
                                translate
                                h-full
                                lg:h-auto
                                md:h-auto
                                border-0
                                rounded-lg
                                shadow-lg
                                relative
                                flex
                                flex-col
                                w-full
                                bg-customModal
                                outline-none
                                focus:outline-none

                            "
                        >

                            {/* HEADER */}
                            <div
                                className="
                                    flex
                                    items-center
                                    p-8
                                    rounded-t
                                    justify-center
                                    relative
                                "
                            >

                                <button
                                    onClick={handleClose}
                                    className=
                                        {`
                                            p-1
                                            border-0
                                            hover:opacity-70
                                            transition
                                            absolute
                                            left-9
                                            ${register? 'text-verde' : 'text-lilas'}
                                        `}
                                   
                                >
                                    <IoMdClose size={18}/>
                                </button>
                                <div className={`absolute top-0 -translate-x-1/2 left-1/2 h-3 w-3/5 ${register? 'bg-verde' : 'bg-lilas'}`}></div>
                                
                            </div>
                            {/* BODY */}
                            <div className="relative p-6 flex-auto w-3/4 mx-auto" >
                                {body}
                            </div>
                            {/* FOOTER */}
                            <div className="flex flex-col p-6 w-3/4 mx-auto text-sm">
                            <p className={centralizeParagraph ? "text-center" : ""}>{paragraphFooter}</p>
                                <div
                                    className="
                                        flex
                                        flex-row
                                        items-center
                                        w-full
                                        mb-2
                                        mt-2
                                    "
                                >
                                    {secondaryAction && secondaryActionLabel &&(
                                        <Button 
                                        
                                        disabled={disabled}
                                        label={secondaryActionLabel}
                                        onClick={handleSecondaryAction } 
                                        />
                                    )}                                    
                                    <Button 
                                        register = {register}
                                        disabled={disabled}
                                        label={actionLabel}
                                        onClick={handleSubmit} 
                                    />
                                </div>
                                {footer}
                            </div>                            
                        </div>   
                    </div>
                </div>
            </div>
        </> 
    )
}

export default Modal