import React, { useEffect, useState } from 'react'

const Footer = () => {

  const [scrollingDown, setScrollingDown] = useState(false);

  const sendMessage = () =>{
    const wppContact = 'https://wa.me/5531996228270?text=Ola,%20estou%20vindo%20pelo%20gigset%20e%20gostaria%20de%20tirar%20uma%20duvida%20com%20voce.'
    window.open(wppContact, '_blank');

  }

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      let scrollTop =   window.scrollY || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setScrollingDown(true);
      } else {
        setScrollingDown(false);
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className={`fixed bottom-0 left-0 w-full z-50 bg-customModal text-white flex flex-row  h-20 text-center transition-transform duration-300 ${scrollingDown ? 'translate-y-full' : 'translate-y-0'}`}>
      <div
        className='
          flex
          items-center
          justify-end
          mr-20
          w-[41%]
        '
      >
        <p >Artistas e produtores todos em um so lugar.</p>
      </div>
      <div
        className='
          flex
          flex-row
          w-[41%]
          bg-lilas
          items-center
          justify-end
        '
      >
        <p className='pr-10 hover:cursor-pointer text-sm md:text-base' onClick={sendMessage}>Alguma duvida? Entre em contato: (31) 99622-8270</p>    

      </div>
      
      
      <div
        className='
          hidden
          
          h-full
          bg-verde
          md:flex
          flex-row 
          items-center
          justify-center
          gap-4
          w-[18%]
        '
      >
        <img height={50} width={50} src ='GigsetSimbolo.png'/>
        <p className='text-lilas font-bold '>© 2024 GIGSET</p>
      </div>
    </footer>
  )
}

export default Footer