import React, { useState } from "react";
import { BiDollar } from "react-icons/bi";
import { FieldError, FieldErrors, FieldErrorsImpl, FieldValues, Merge, UseFormRegister } from "react-hook-form";
import  InputMask   from "react-input-mask";
import { FaRegEye } from "react-icons/fa6";
import Placeholder from "react-select/dist/declarations/src/components/Placeholder";

interface InputProps {
  id: string;
  label: string;
  type?: string;
  disabled?: boolean;
  formatPrice?: boolean;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  mask?: string;
  validate?: (value: string) => boolean | string;
  Placeholder?: string;
  needEvaluation?: boolean;
  passWordShow?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function validarSenha(senha: string): string {
  const comprimentoMinimo: number = 8;
  const temLetrasMinusculas: boolean = /[a-z]/.test(senha);
  const temLetrasMaiusculas: boolean = /[A-Z]/.test(senha);
  const temDigitos: boolean = /[0-9]/.test(senha);
  const temCaracteresEspeciais: boolean = /[!@#$%^&*()-_=+[\]{}|;:,.<>?/~`]/.test(senha);
  if (senha === "") return ""

  if (temLetrasMinusculas && temLetrasMaiusculas && temDigitos && temCaracteresEspeciais) {
      return 'Forte';
  } else if (temLetrasMinusculas || temLetrasMaiusculas || temDigitos || temCaracteresEspeciais) {
      return 'Intermediária';
  } else {
      return 'Fraca';
  }
}


const getCorForcaSenha = (forca: string): string => {
  switch (forca) {
    case 'Intermediária':
      return 'text-customYellow';
    case 'Forte':
      return 'text-verde';
    default:
      return 'text-customRed';
  }
};


const Input: React.FC<InputProps> = ({
  id,
  label,
  type,
  disabled,
  formatPrice,
  required,
  register,
  errors,
  mask, 
  validate,
  Placeholder,
  needEvaluation,
  passWordShow = true,
  onChange
}) => {
  const today = new Date().toISOString().split("T")[0];

  

  const [inputType, setInputType] = useState(type);
  const [passwordStrength, setPasswordStrength] = useState("")
  const [passwordStrengthColor,setPasswordStrengthColor] = useState("text-customRed")

  const toggleTipoSenha = () => {
    const newType = inputType === 'password' ? 'text' : 'password';
    setInputType(newType);
  };

  const getPasswordStrength = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const novaSenha = event.target.value;
    const novaForcaSenha = validarSenha(novaSenha);
    setPasswordStrength(novaForcaSenha);
    setPasswordStrengthColor(getCorForcaSenha(novaForcaSenha));
  }


  const renderError = (error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined) => {
    if (error?.message && typeof error.message === 'string') {
      return <p className="text-redError text-sm" >{error.message}</p>;
    }
    return null;
  };

  return (
    
    
    <div className="w-full relative">
      {formatPrice && (
        <BiDollar
          size={24}
          className="
            absolute
            top-8
            left-2
          "
        />
      )}
      <label
        htmlFor={id}
        className={`
          text-sm
          z-5
          ${errors[id]? "text-redError" : "text-customText"}
        `}
      >
        {label}{required && <label className={errors[id]? "text-redError" : "text-verde"}>*</label>}
      </label>
      <InputMask
        mask={mask? mask: ''} 
        id={id}
        disabled={disabled}
        {...register(id, {
          required: required ? `${label} é obrigatório` : false,
          validate: validate,
          pattern: id === 'identifier' || id === 'userName' ? {
              value: /^[A-Za-z0-9.-]+$/,
              message: "Caracteres especiais não são permitidos"
          } : undefined
        })}
        placeholder={Placeholder}
        type={inputType}
        onChange={(e) => {
          getPasswordStrength(e);
          if (onChange) onChange(e);
          }}
        className={`
          peer
          w-full
          py-1.5
          px-2.5
          font-light
          bg-customCards
          border-2
          leading-12px
          rounded-md
          outline-none
          transition
          focus:inherit
          filled:inherit
          disabled:opacity-70
          disabled:cursor-not-allowed
          ${formatPrice? "pl-9" : "pl-4"}
          ${errors[id]? "border-redError" : "border-customCards"}
          ${errors[id]? "focus:border-redError" : "focus:border-lilas"}
        `}
      />
      {type == "password" && passWordShow &&
        <button
          className="
            text-verde
          "
          onClick={toggleTipoSenha}
        >
            <FaRegEye  
            size={16}
            className={`
              absolute
              right-2
              ${errors['password'] || errors['newPassword']  ? 'bottom-7 text-redError': 'bottom-2' }
            `}
            />
        </button>
        
        
      }
      
      {type === "password" && validate && needEvaluation &&
        <p className={`absolute right-3 ${passwordStrengthColor}`}>{passwordStrength}</p>
      }
      {renderError(errors[id] as FieldError | Merge<FieldError, FieldErrorsImpl<any>>)}
      
    </div>

  );
};

export default Input;