import { useEffect, useMemo, useState } from "react";
import AuthenticatedContainer from "../../components/containers/AuthenticatedContainer";
import PortifolioEditSection from "../../components/portifolio/PortifolioEditSetion";
import Input from "../../components/inputs/Input";
import {  Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import SelectComponent from "../../components/inputs/Selectors/SelectComponent";
import DynamicInputs from "../../components/inputs/DynamicInputs";
import DynamicItemCard from "../../components/inputs/DynamicItemCard";
import DynamicTextCard from "../../components/inputs/DynamicTextCard";
import ImageUploader from "../../components/inputs/Uploads/ImageUploader";
import AvatarUploader from "../../components/inputs/Uploads/AvatarUploader";
import CoverUploader from "../../components/inputs/Uploads/CoverUploader";
import { musicStyles } from "../../utils/StaticData";
import { ProjectService } from "../../services/ProjectService";
import { useLocation, useNavigate } from "react-router-dom";
import Project from "../../models/Project";
import Button from "../../components/Button";
import toast from "react-hot-toast";
import InputPrice from "../../components/inputs/InputPrice";
import MissingToolTip from "../../components/portifolio/MissingToolTip";



type TOptions = {
  value: string;
  label: string;
  png?: string;
}

const InputCardArtists = {
  label1: 'Nome',
  label2: 'Função',
  label3: 'Foto'
};

const InputCardLocation = {
  label1: 'Evento',
  label2: 'Localização',
  label3: 'Foto'
};

const path = process.env.REACT_APP_MINIO_URL

const PortifolioEditing = () =>{
  const { pathname } = useLocation();
  const identifier = pathname.split('/').pop();

  const navigate = useNavigate();

  const [coverImage, setCoverImage] = useState<string []>([]); 
  const [profileImage, setProfileImage] = useState<string[]>([]);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false)
  const [project, setProject] = useState<Project>(); 
  const projectService = useMemo(() => new ProjectService(), []);
  const [missingFields, setMissingFields] = useState<string[]>([]);


  useEffect(()=>{
    projectService.getProjectByIdentifierForEditing('/' + identifier).then((response)=>{
        
      setProject(response)
      handleInitializeEditing(response)
    }).catch(()=>{ navigate('/user/myprojects')});
  },[identifier])
  
  const handleCoverUpload = (files: string[]) => {
    setCoverImage(files);
  };

  const handleProfileUpload = (files: string[]) => {
    setProfileImage(files);
  };

  const handleGalleryUpload = (files: string[]) => {
    //setGalleryImages(files);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState:{
        errors,
    },
  } = useForm<FieldValues>()

  const setCustomValue = (id : string, value: any) => {
    setValue(id,value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
  }
 

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    data.identifier =  data.identifier.toLowerCase().replace(/\s+/g, '');
    if(!project) return;
    setIsLoading(true)
    projectService.updateProject(data,project.id).then((res)=>{
      toast.success('Portifolio editado!');
    }).catch((error)=>{
      toast.error('Erro ao editar portifolio!');
    }).finally(()=>{
      setIsLoading(false);
    });
    
}

  const city = watch('localization.city')
  const cityValue = city && city.length > 1 ? stringToTOptions(city) : undefined;
  const state = watch('localization.state');
  const stateValue = state && state.length>1 ? stringToTOptions(state): undefined;
  const country = watch('localization.country');
  const countryValue = country && country.length>1 ?stringToTOptions(country): undefined;
  const musicStyle1 = watch('musicStyle.musicStyle1'); 
  const musicStyle1Value = musicStyle1 && musicStyle1.length>1 ? stringToTOptions(musicStyle1):undefined; 
  const musicStyle2 = watch('musicStyle.musicStyle2');
  const musicStyle2Value = musicStyle2 && musicStyle2.length>1 ? stringToTOptions(musicStyle2): undefined; 
  const musicStyle3 = watch('musicStyle.musicStyle3');
  const musicStyle3Value = musicStyle3 && musicStyle3.length>1 ? stringToTOptions(musicStyle3): undefined; 
  const musicStyle4 = watch('musicStyle.musicStyle4');
  const musicStyle4Value = musicStyle4 && musicStyle4.length>1 ? stringToTOptions(musicStyle4): undefined; 

  const minPrice =  watch('logisticDetail.minPrice')
  const maxPrice =  watch('logisticDetail.maxPrice')
  const description = watch('description');
  const phone = watch('logisticDetail.phone');

  const handleInitializeEditing = (project: Project) =>{
      setCustomValue('name', project.name)
      setCustomValue('identifier', project.identifier)
      setCustomValue('musicStyle', project.musicStyle)
      setCustomValue('localization', project.localization)
      setCustomValue('description', project.description)
      setCustomValue('media', project.media)
      setCustomValue('musicLinks', project.musicLinks)
      setCustomValue('apresentedIn', project.apresentedIn)
      setCustomValue('artists', project.artists)
      setCustomValue('includedIn', project.includedIn)
      setCustomValue('promoMaterial',project.promoMaterial)
      setCustomValue('logisticDetail', project.logisticDetail)
      setCustomValue('faq', project.faq)
      setCoverImage(project.media?.coverImage ? project.media.coverImage : []);
      setProfileImage(project.media?.profilePic ? project.media.profilePic  : [])
      setGalleryImages(project.media?.showPictures ? project.media.showPictures : [])
  }


  useEffect(()=>{
    const missing = [];

    if (!musicStyle1) missing.push("Estilo musical 1")
    if (!city) missing.push("Cidade")
    if (!state) missing.push("Estado")
    if (!country) missing.push("País")
    if (!description) missing.push("Descrição")
    if (!phone) missing.push("Telefone")

    setMissingFields(missing)

  },[musicStyle1,city,state,country,description,phone])
  


  const menu = (
    <section 
      className="
        flex
        flex-row
        items-center
        w-11/12
        md:w-2/12
        gap-5
        py-4
      "
    >
      <a className="text-lilas" href="/user/myprojects">Voltar</a>
      |
      <Button
        label="Atualizar"
        small
        onClick={handleSubmit(onSubmit)}
        disabled={isLoading}
      />

    </section>
  )

  const topMenu = (
    <div className="text">
      {project?.active?
      <p className="text-verde">Parabens seu projeto esta ativo</p>
      :
      <p className="text-customYellow">Seu projeto não esta ativo, verifique os campos com *</p>
      
      
      }
      
    </div>
  )

  
  return (
        <AuthenticatedContainer
            title="Página do artista"
            subtitle="Editar"
            menu={menu}
            topMenu = {topMenu}
        >
          
          <PortifolioEditSection
            title="Imagem de abertura "
          >
            <div className="flex flex-col gap-4 md:gap-0 h-auto md:min-h-[620px]">
              <label>Imagem de capa</label>
              <div>
              <ImageUploader imagens={coverImage} onSetPathValue={(value:string)=>setCustomValue('media.coverImage',value)} pathAndName={`${identifier}/cover/imagem`} specification="A imagem deve ter 1900px de largura por 620px de altura e tamanho menor que 5MB" maxFiles={1} onUpload={handleCoverUpload}>
                {coverImage && coverImage.length > 0 && coverImage[0] && <CoverUploader  src={coverImage[0].includes('blob')? coverImage[0]: path + coverImage[0]}/>
                }     
              </ImageUploader>
              </div>
            </div>
          </PortifolioEditSection>    
          <PortifolioEditSection
            title="Informações sobre o artista ou banda*"
          >
            <div 
            id="basicInformation"
            className="
                flex
                flex-col
                md:flex-row
                w-full
                gap-12
              ">
              <div
                className="
                  flex
                  flex-col
                  w-full
                  md:w-1/2
                  gap-2
                "
              >
                <Input 
                  register={register}
                  errors={errors} 
                  label="Nome" 
                  id="name" 
                  Placeholder="Digite o nome do projeto artistico"
                />
                <Input 
                  register={register}
                  errors={errors} 
                  label="Username do projeto" 
                  id="identifier" 
                  Placeholder="Digite o nome do projeto artistico"
                />
                <label
                 className="text-lg">Localização*</label>
                <div 
                  className="
                    flex
                    flex-col
                    md:flex-row
                    w-full
                    justify-between
                    gap-4
                  "
                >
                  <Input
                    id={'localization.country'}
                    label="País*"
                    register={register}
                    errors={errors}
                    Placeholder="País"
                    onChange={() => trigger("localization.state")}
                  />
                  <Input
                    id="localization.state"
                    label="Estado*"
                    register={register}
                    errors={errors}
                    Placeholder="Estado"
                    onChange={() => trigger("localization.state")}
                  />
                  <Input
                    id="localization.city"
                    register={register}
                    errors={errors}
                    label="Cidade*"
                    Placeholder="Cidade"
                    onChange={() => trigger("localization.city")}
                  />
                </div>
              </div>
              <div 
                className="
                w-full  
                md:w-1/2
                "
              
              >
                Imagem do perfil
                <ImageUploader imagens={profileImage} onSetPathValue={(value:string)=>setCustomValue('media.profilePic',value)} pathAndName={`${identifier}/profile/imagem`} specification="A imagem deve ter 250px por 250px" maxFiles={1} onUpload={handleProfileUpload}>
                  {profileImage && profileImage.length > 0 && profileImage[0] && <AvatarUploader  src={profileImage[0].includes('blob')?  profileImage[0] :path + profileImage[0]}/> }                  
                </ImageUploader>
              </div>
            </div>
            <div
              id="musicStyles"
              className="
                mt-8
                flex
                flex-col
              "
            >
              <p className="text-lg">Estilo musical *</p>
              <p className="text-xs mt-4">Escolha até 4 estilos musicais</p>
              <div
                className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-5"
              >
                <SelectComponent
                  value={musicStyle1Value}
                  fieldName="musicStyle.musicStyle1"
                  control={control}
                  label="Estilo musical 1 *"
                  placeholder="Escolha Estilo musical 1"
                  options={musicStyles}
                />
                <SelectComponent
                  value={musicStyle2Value}
                  fieldName="musicStyle.musicStyle2"
                  control={control}
                  label="Estilo musical 2"
                  placeholder="Escolha Estilo musical 2"
                  options={musicStyles}
                />
                <SelectComponent
                  value={musicStyle3Value}
                  fieldName="musicStyle.musicStyle3"
                  control={control}
                  label="Estilo musical 3"
                  placeholder="Escolha Estilo musical 3"
                  options={musicStyles}
                />
                <SelectComponent
                  value={musicStyle4Value}
                  fieldName="musicStyle.musicStyle4"
                  control={control}
                  label="Estilo musical 4"
                  placeholder="Escolha Estilo musical 4"
                  options={musicStyles}
                />
              </div>

            </div>
            <div
              id="details"
              className="
              mt-5
              flex
              flex-col
              "
            >
            <p className="text-lg">Sobre o projeto artístico *</p>
            <p className="text-xs mt-4">Descreva aqui, sobre seu projeto artístisco</p>
            <textarea
              className={`
                peer
                w-full
                h-40  
                py-1.5
                px-2.5
                font-light
                bg-customCards
                border-2
                border-customCards
                focus:border-lilas
                leading-5
                rounded-md
                outline-none
                transition
                disabled:opacity-70
                disabled:cursor-not-allowed
              `}
              {...register('description', {})}
            />
            </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="Informação sobre os integrantes"
          >
            <div id="Artistas">
              <DynamicItemCard
                initialValues={project?.artists}
                control={control}
                imagePathName={`${identifier}/artists`}
                fieldName="artists"
                inputData={InputCardArtists}
              />
            </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="Imagens de shows "
          >
            <p className="text-xs">Essas imagens serão utilizadas para a demonstração do card</p>
            <div >
              <ImageUploader imagens={(galleryImages) || []} onSetPathValue={(value:string)=>setCustomValue('media.showPictures',value)} pathAndName={`${identifier}/galery/imagem`} maxFiles={4} onUpload={handleGalleryUpload} />            
            </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="Video de shows"
          >
            <p className="text-sm">Copie e cole os links para os vídeos do Youtube</p>
             <div
               className="
               w-full
               md:w-1/2
               "
             >
              <DynamicInputs
                initialValues={project?.media?.youtubeLinks}
                control={control}
                fieldName="media.youtubeLinks"
              />
            </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="Perfís musicais"
          >
            <p className="text-sm">Copie e cole os códigos de seus perfís</p>
            <div
              className="w-full md:w-1/2"
            >
                <Input 
                  register={register}
                  errors={errors} 
                  label="Spotify (perfil do spotify)" 
                  id="musicLinks.spotify" 
                  Placeholder="Ex: 7CajNmpbOovFoOoasH2HaY"
                />
                {/* <Input 
                  register={register}
                  errors={errors} 
                  label="Deezer" 
                  id="musicLinks.deezer" 
                  Placeholder="Digite o código do Deezer"
                /> */}
                <Input 
                    register={register}
                    errors={errors} 
                    label="SoundCloud (perfil do soundcloud)" 
                    id="musicLinks.soundCloud" 
                    Placeholder="ex : https://w.soundclo..."
                  />
              </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="Onde já se apresentaram"
          >
             <div>
              <DynamicItemCard
                initialValues={project?.apresentedIn}
                control={control}
                imagePathName={`${identifier}/apresentedIn`}
                fieldName = 'apresentedIn'
                inputData={InputCardLocation}
              />
            </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="O que pode estar incluso na apresentação"
          >
            <div id="Included">
              <DynamicTextCard
                initialValues={project?.includedIn}
                fieldName="includedIn"
                control={control}
                options = {musicStyles}
                label="Tipo de equipamento"
                placeholderInput = "Nome do equipamento"
                placeholder="Especificações de equipamento"
              />
            </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="Materiais promocionais para download"
          >
            <div>
              <p className="text-xs">Adicione logotipos, impressos e vídeos relacionados ao seu evento(links de hospedagem)</p>
              <div
                className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-5"
              >

                <Input
                  Placeholder="Link da pasta com o logotipo"
                  label="Logotipo"
                  errors={errors}
                  register={register}
                  id="promoMaterial.logo"
                />
                <Input
                  Placeholder="Link da pasta com flyer de eventos passados"
                  label="Flyer"
                  errors={errors}
                  register={register}
                  id="promoMaterial.flyer"
                />
                <Input
                  Placeholder="Link da pasta com o preskit"
                  label="Preskit"
                  errors={errors}
                  register={register}
                  id="promoMaterial.preskit"
                />
                <Input
                  Placeholder="Link da pasta com o video promocional"
                  label="Video"
                  errors={errors}
                  register={register}
                  id="promoMaterial.video"
                />
              </div>
            </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="Perguntas frequentes"
          >
            <div id = "faq">
            <DynamicTextCard
              initialValues={project?.faq}
              fieldName="faq"
              control={control}
              options={musicStyles}
              label="Pergunta"
              placeholder="Insira a resposta"
              placeholderInput="Insira a pergunta"
            />

            </div>
          </PortifolioEditSection>
          <PortifolioEditSection
            title="Dados relacionados ao orçamento"
          >
            <p className="text-verde">Apenas números</p>
            <div
                className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-5"
              >

                <InputPrice
                  label="Valor padrão (valor em reais)"
                  register={register}
                  id="logisticDetail.maxPrice"
                  value={maxPrice}
                />
                <InputPrice
                  label="Valor Minimo (valor em reais)"
                  register={register}
                  id="logisticDetail.minPrice"
                  value={minPrice}
                />
                <Controller
                  name="logisticDetail.landDistance"
                  control={control}
                  rules={{
                    required: "Este campo é obrigatório",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Apenas dígitos são permitidos"
                    }
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Distância máxima em logística terrestre (KM)"
                      errors={errors}
                      register={register}
                      id="logisticDetail.landDistance"
                    />
                  )}
              />
                <Input
                  label="Número de integrantes da equipe"
                  errors={errors}
                  register={register}
                  id="logisticDetail.team"
                />
                <Input
                  label="Telefone*"
                  errors={errors}
                  register={register}
                  id="logisticDetail.phone"
                  mask="(99) 99999-9999"
                />
              </div>
          </PortifolioEditSection>
          <div
            className="w-full md:w-1/5"
          >
            <Button
             label="Atualizar"
             small
             onClick={handleSubmit(onSubmit)}
             disabled={isLoading}
            />
          </div>
          <MissingToolTip missingFields={missingFields} />
        </AuthenticatedContainer>
    )

}

const stringToTOptions = (stringValue: string): TOptions => {
  return {
      value: stringValue,
      label: stringValue,
  };
};


export default PortifolioEditing;