import React from "react";
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import useSearchModal from "../../hooks/useSearch";



const AdvancedSearch = () =>{
    const searchModal = useSearchModal();
    

    return(
        <div
            onClick={()=>{searchModal.onOpen()}}
            className="
                flex-row
                flex
                text-sm
                font-semibold
                py-2
                px-5
                rounded-full
                bg-lilas
                hover:bg-lilashover
                transition
                cursor-pointer
                items-center
                overflow-hidden
            "
        >
        
            <div
            
                className="
                    pr-2
                    flex
                "
            >Busca avançada</div>
            <div className="text-xl">
                <HiAdjustmentsHorizontal/>
            </div>
        </div>
    )
}

export default AdvancedSearch