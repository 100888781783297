import React from "react";
import {  useNavigate  } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import CardSlider from "../photo/CardSlider";
import ProjectCardDto from "../../models/ProjectCardDTO";
import { RiCloseCircleFill } from 'react-icons/ri';
import { NumericFormat } from "react-number-format";



interface IPortifolioCardProps {
  data : ProjectCardDto;
  onClick?: () => void;
  isEditing?: boolean;
};

const PortifolioCard: React.FC<IPortifolioCardProps> = ({
  data,
  onClick,
  isEditing
}) => {
  

  const navigate = useNavigate();


  const handleNavigate = (newTab: boolean, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event) {
      event.preventDefault();
    }
  
    const url = `${data.identifier}`;
    if (newTab) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      navigate(url, { state: data.identifier });
    }
  };

  const showPictures = data.showPictures?.filter(e => e !== '' && e !== undefined)
  const coverImage = data.coverImage?.filter(e => e !== '' && e !== undefined)



  return (
    <div 
      onClick={() => handleNavigate(false)}
      onContextMenu={(e) => handleNavigate(true, e)}
      className="relative col-span-1 cursor-pointer group bg-customCards rounded-[20px] aspect-[333/387]"
    > 
        

      <div className="relative flex flex-col w-full h-full">
        <div 
          className="
            w-full 
            relative 
            overflow-hidden 
            rounded-t-[20px]
            h-3/5 
          "
        >
          <CardSlider
           images={showPictures? showPictures : coverImage}
          />
          <div className="
            absolute
            top-3
            right-3
          ">
          </div>
        </div>
        <div className="p-4 text-sm">
          <div translate="no" className="font-semibold  text-lilas">
            {data.name}
          </div>
          <div translate="no" className="flex items-center gap-3">
            {data.mainStyle} {data.numberOfStyles>0 &&  " + " + data.numberOfStyles}
          </div>
          {data.state &&
            <div translate="no" >
              {data.city} | {data.state} | BR
            </div>
          }
          {data.rate &&
            <div className="flex flex-row items-center gap-2 pt-3 pb-3">
              <FaStar className="text-lilas" /> 5,0
            </div>
          }
          {data.minPrice !=0 &&
          <>
          <hr className="border-darkGray"/>
          
            <div className="flex flex-row items-center gap-2 mt-2">
              <label>A partir de: </label>
              <NumericFormat
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale={true}
                value={data.minPrice}
                displayType="text"
              />
            </div>
          </>
          }
          {isEditing &&
            <section
              className="
                absolute
                bottom-3
              "
            >
              {data.active?
                <label className="text-verde">Portifolio ativo</label>
                :
                <section className="flex flex-col md:gap-1">
                  <label className="text-redError">Portifolio intativo</label>
                </section>
              }
  
            </section>
          }
        </div>
      {isEditing &&
         <button
             className="absolute z-50 top-4 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
             onClick={(e)=>{
               e.stopPropagation();
               onClick && onClick()}}
           >
             <RiCloseCircleFill size={18} />
           </button>
      } 
      </div>
    </div>
   );
}
 
export default PortifolioCard;