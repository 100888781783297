import React,{useCallback, useState} from "react";
import {FcGoogle} from "react-icons/fc";
import {
    FieldValues,
    SubmitHandler,
    useForm
} from 'react-hook-form';

import useLoginModal from "../../hooks/useLoginModal";
import Modal from "./Modal";
import Heading from "../Heading";
import Input from "../inputs/Input";
import { toast } from 'react-hot-toast'; 
import Button from "../Button";
import useRegisterModal from "../../hooks/useRegisterModal";
import { useAuth } from "../../hooks/useAuth";
import useRecoverPasswordModal from "../../hooks/useRecoverPasswordModal";

const LoginModal = () =>{

    const registerModal = useRegisterModal();
    const loginModal = useLoginModal();
    const passwordRecoverModal = useRecoverPasswordModal();
    const [isLoading,setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {
            errors,
        },
        reset
    } = useForm<FieldValues>({
        defaultValues:{
            username: '',
            password: ''
        }
    });

    const { login } = useAuth()
    const onSubmit: SubmitHandler<FieldValues> = (data) =>{
        data.username = data.username?.toLowerCase()
        setIsLoading(true);
        login(data.username,data.password)
        .then(()=>{
            reset()
            toast.success("Voce foi logado")
            loginModal.onClose();
            window.location.href = '/user/myprojects'; 
        })
        .catch((error) =>{
            toast.error("Usuario e senha invalidos!")
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }

    const toggle = useCallback(()=>{
        loginModal.onClose();
        registerModal.onOpen();
    },[loginModal,registerModal])
    
    const bodyContent = (
        <div className="flex flex-col  space-y-2">
            <Heading 
                title="Olá, faça seu login:" 
                center
            />
            <Input 
                id="username" 
                label="Nome de usuário ou Email" 
                disabled = {isLoading}
                register={register}
                errors={errors}
                required
            />
            <Input 
                id="password" 
                label="Password" 
                type="password" 
                disabled = {isLoading}
                register={register}
                errors={errors}
                required
            />
        </div>
    )

    const footerContent = (
        <div className="flex flex-col">
            {/* <Button
                outline
                label="Continue com Google"
                icon={FcGoogle}
                onClick={()=>{}}
            /> */}
            <div
                className="
                    text-center
                    font-light
                "
            >
                <div className="justify-center flex flex-row items-center gap-2">
                    <div>
                        Primeira vez no Gigset?
                    </div>
                    <div
                        onClick={toggle}
                        className="
                            text-verde
                            cursor-pointer
                            hover:underline
                        "
                    >
                        Crie sua conta gratuita.
                    </div>
                </div>

            </div>
        </div>
    )

    const paragraphFooter = (
        <button  onClick={()=>{
            loginModal.onClose()
            passwordRecoverModal.onOpen()}}>
            <a>Esqueci minha senha</a>
        </button>
    )

    return(
          <Modal
            disabled={isLoading}
            isOpen = {loginModal.isOpen}
            title="Login"
            actionLabel="Entrar"
            onClose={loginModal.onClose}
            onSubmit={handleSubmit(onSubmit)}
            body = {bodyContent}
            footer={footerContent}
            paragraphFooter = {paragraphFooter}
          />
    )
}

export default LoginModal