import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import TextInput from './TextInput';
import ItemText from '../../models/ItemText';


type InputData = {
    label1: string;
    label2: string;
    label3: string;
  };

  
type TOptions = {
  value: string;
  label: string;
  png?: string;
}

interface IDynamicItemCardProps {
    label: string;
    placeholder: string;
    options: TOptions[];
    control: Control<FieldValues,any>;
    fieldName: string;
    initialValues: ItemText[] | undefined;
    placeholderInput: string;
}

const DynamicTextCard: React.FC<IDynamicItemCardProps> = ({
    label,
    placeholder,
    options,
    control,
    fieldName,
    initialValues,
    placeholderInput
}) => {
  const [inputs, setInputs] = useState([{ item: '', content: '', active: true },{ item: '', content: '', active: false }]);

  const { remove } = useFieldArray({
    control,
    name: fieldName
  });

  useEffect(() => {
    if (initialValues) {
      const updatedInitialValues = initialValues
        .map(value => {
          if (value.item == null) return null; // Retorna null para valores inválidos
          return {
            ...value,
            active: true
          };
        })
        .filter((value): value is ItemText & { active: boolean } => value !== null); // Filtra valores null e garante o tipo
  
      setInputs([...updatedInitialValues,{ item: '', content: '', active: true }, { item: '', content: '', active: false }]);
    }
  }, [initialValues]);
  


  const activate = (index: number) =>{
    const activeInput = [...inputs];
    activeInput[index] = {
      ...activeInput[index],
      ["active"] : true
    };
    setInputs([...activeInput, { item: '', content: '', active: false }]);

  }

  const handleInputChange = (index: number, field: string, value: string) => {
    setInputs(prevInputs=>{
      const newInputs = [...prevInputs];
      newInputs[index] = {
        ...newInputs[index],
        [field]: value
      };

      if (index === prevInputs.length - 2 && newInputs[index].item?.trim() !== '' && newInputs[index].content?.trim() !== '') {
        newInputs[index+1] = {
          ...newInputs[index+1],
          ["active"] : true
        };
        return [...newInputs, { item: '', content: '', active: false }];
      }

      return newInputs; 
    } )

  };

  const cleanInput = (index: number) => {
    const cleanInputs = [...inputs];
    cleanInputs.splice(index, 1);
    setInputs(cleanInputs);
    remove(index)
  }

  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7">
        <AnimatePresence>
          {inputs.map((input, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
              className="relative flex flex-row"
            >
            <TextInput
                control={control}
                options={options}
                active = {input.active}
                onRemove = {() => cleanInput(index)}
                onActivate = {()=> activate(index)}
                label={label}
                placeholder={placeholder}
                onChange={handleInputChange}
                index = {index}
                fieldName = {fieldName}
                subNameValue={input.content}
                nameValue={input.item}
                placeholderInput = {placeholderInput}
            />             
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default DynamicTextCard;